<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
        @addNotice="addNotice"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @editNotice="editNotice"
        @deleteNotice="deleteNotice"

    />
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addNoticeData"
        @addNoticeSub="addNoticeSub"
    />

  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'
import {getNotice, getNoticeDetile, addNotice, deleteNotice, editNotice} from '@/api/notice_management/notice_management';

export default {
  components: {},
  props: [],
  data() {
    return {
      //title 数据源
      titleProp: {
        name: '公告管理',//标题名称
        butOperate: [{
          name: '添加',
          type: 'primary',
          method: 'addNotice',
          id: 2000,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '公告标题',
          field: 'name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '公告内容',
          field: 'content',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '创建日期',
          field: 'updated_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '编辑',
            type: 'primary',
            method: 'editNotice',
            status: '20000',
            id: 20000,
          }, {
            name: '删除',
            type: 'danger',
            method: 'deleteNotice',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //addDialog数据源
      addNoticeData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addNoticeSub',//点击确定方法名
        title: '添加公告',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '公告标题',
          field: 'name',
          type: 2,
          show: true,
          required: true,
        },{
          label: '公告内容',
          field: 'content',
          type: 6,
          show: true,
          required: true,
        },{
          label: '附件上传',
          field: 'attachment',
          type: 8,
          show: true,
        }],
        //dialog 显示数据
        addDialogShowData: {
          name: '',
          content: '',
          attachment: [],
        },
        // 下拉框的 options和单选框，多选框
      },
      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {},
      editId:'',

    };
  },
  created() {
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        // keywords: this.tabsProp.keywords,
        // this.tabsProp.active //tabs 当前的值
        // name: this.tabsProp.heighKewWordField.name
      }
      getNotice(params).then((result) => {
        console.log(result)
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
      })
    },
    //title点击 添加类型 按钮
    addNotice() {
      console.log('添加')
      this.addNoticeData.title = '添加公告'
      this.addNoticeData.addDialogShowData.name = ''
      this.addNoticeData.addDialogShowData.content = ''
      this.addNoticeData.addDialogShowData.attachment = []
      this.editId = ''
      this.addNoticeData.isDialog = true;
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.keywords);//搜索的值
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.heighKewWordField);//高级搜索的字段
    },

    //table 编辑 按钮点击
    editNotice(row, index) {
      console.log(row)
      console.log(index)
      console.log('编辑 点击')
      getNoticeDetile({id: row.id}).then((result) => {
        this.addNoticeData.addDialogShowData.name = result.data.name
        this.addNoticeData.addDialogShowData.content = result.data.content
        if(result.data.attachment){
          this.addNoticeData.addDialogShowData.attachment = JSON.parse(result.data.attachment)
        }
      })
      this.editId = row.id
      this.addNoticeData.title = '编辑公告'
      this.addNoticeData.isDialog = true
    },
    //table 作废 按钮点击
    deleteNotice(row, index) {
      console.log(row)
      console.log(index)
      console.log('作废 点击')
      deleteNotice({id: row.id}).then((result) => {
        this.$message({
          message: result.msg,
          type: 'success'
        });
        this.tableData.splice(index, 1)
      })
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addNoticeSub() {
      this.subData = {...this.addNoticeData.addDialogShowData}  //ES6深拷贝

      //必填信息判断
      if (!this.subData.name || !this.subData.content ){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }

      this.subData.attachment = JSON.stringify(this.subData.attachment)
      if (this.editId){
        console.log('编辑')
        this.subData.id = this.editId
        console.log(this.subData)
        editNotice(this.subData).then((result) => {
          this.addNoticeData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      } else {
        console.log('添加')
        console.log(this.subData)
        addNotice(this.subData).then((result) => {
          this.addNoticeData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
