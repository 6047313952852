import http from '../http';

export function getNotice (params) {
    return http({
        url: "/api/announcement/announcement",
        method: "get",
        params:params
    })
}

export function getNoticeDetile (params) {
    return http({
        url: "/api/announcement/announcementDetail",
        method: "get",
        params:params
    })
}

export function addNotice (data) {
    return http({
        url: "/api/announcement/announcement",
        method: "put",
        data:data
    })
}
export function deleteNotice (data) {
    return http({
        url: "/api/announcement/announcement",
        method: "delete",
        data:data
    })
}
export function editNotice (data) {
    return http({
        url: "/api/announcement/announcement",
        method: "post",
        data:data
    })
}
